import clsx from 'clsx';
import React from 'react';
import { NavLink, To } from 'react-router-dom';

import { SVG } from '../../_atoms/SVG';

export function FooterMobile() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const actions = React.useMemo<Omit<TypePropsAnimatedButton, "index">[]>(
    () => [
      {
        isOpen,
        to: "/inspections/create-new-inspection",
        label: "New Inspection",
      },
      {
        isOpen,
        to: "/templates",
        label: "Templates Hub",
      },
      {
        isOpen,
        to: "/settings?tab=services",
        label: "Services",
      },

      {
        isOpen,
        to: "/settings?tab=agreements",
        label: "Agreements",
      },

      {
        isOpen,
        to: "/settings?tab=payment",
        label: "Payment Settings",
      },

      {
        isOpen,
        to: "/settings?tab=profile",
        label: "Profile",
      },
    ],
    [isOpen]
  );

  return (
    <div className="footer-mobile">
      <div className="add-new">
        <div className="action" onClick={toggle}>
          {actions.map((propsBTN, index) => (
            <AnimatedButton {...propsBTN} index={index + 1} />
          ))}

          <div className="action-bg" />

          <SVG
            name="add"
            className={clsx(
              "svg-add",
              `svg-add-${isOpen ? "active" : "inactive"}`
            )}
          />
        </div>
        <SVG name="footer" className={clsx("svg-footer")} />
      </div>

      <div className="actions">
        <div className="actions-side">
          <NavLink to="/workspace" aria-label="/workspace">
            <SVG name="dashboard" className="svg-dashboard" />
          </NavLink>

          <NavLink to="/inspections" aria-label="/inspections">
            <SVG name="list" className="svg-list" />
          </NavLink>
        </div>

        <div className="actions-side">
          <NavLink to="/settings" aria-label="/settings">
            <SVG name="settings" className="svg-settings" />
          </NavLink>

          <NavLink to="/contact-us" aria-label="/contact-us">
            <SVG name="contact-us" className="svg-contact-us" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

function AnimatedButton({
  isOpen,
  index,
  className,
  label,
  to,
}: TypePropsAnimatedButton) {
  const baseClassName = `action-btn-${index}`;

  return (
    <NavLink
      className={clsx(
        baseClassName,
        `${baseClassName}-${isOpen ? "active" : "inactive"}`,
        className,
        className ? `${className}-${isOpen ? "active" : "inactive"}` : undefined
      )}
      to={to}
    >
      {label}
    </NavLink>
  );
}

type TypePropsAnimatedButton = {
  isOpen: boolean;
  index: number;
  className?: string;
  label: string;
  to: To;
};
